/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2013/01/lumbre2.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 400px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.31645569620254%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABhMaoEkUZNT//xAAZEAACAwEAAAAAAAAAAAAAAAABAgAREhP/2gAIAQEAAQUCVbnE4qKs2vEiz//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGRj//EABcRAQADAAAAAAAAAAAAAAAAAAABAhH/2gAIAQIBAT8BmzX/xAAcEAABAwUAAAAAAAAAAAAAAAARAAEQAgMSIUH/2gAIAQEABj8CWfCI0nt0sI//xAAaEAEAAgMBAAAAAAAAAAAAAAABACERMUFh/9oACAEBAAE/IUdRrrgzNxkhsAe+xsjuf//aAAwDAQACAAMAAAAQmM//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EEzSD//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QdcEf/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAMSFB4f/aAAgBAQABPxCF4OroqkKnxcuQIjExAAh2s3jYKKvifud9nd//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"lumbre\"\n        title=\"lumbre\"\n        src=\"/static/b283951d9a3abce2f9c0aae1e2818101/066f9/lumbre2.jpg\"\n        srcset=\"/static/b283951d9a3abce2f9c0aae1e2818101/ff44c/lumbre2.jpg 158w,\n/static/b283951d9a3abce2f9c0aae1e2818101/a6688/lumbre2.jpg 315w,\n/static/b283951d9a3abce2f9c0aae1e2818101/066f9/lumbre2.jpg 400w\"\n        sizes=\"(max-width: 400px) 100vw, 400px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "Una vez leí de pasada, no recuerdo donde, que el hogar era el mito más extendido del ser humano, es posible, la idea del hogar como lugar donde se reúne la familia está muy extendida, hasta los nómadas necesitan algún sitio donde relajarse, aunque sea un lugar itinerante."), "\n", React.createElement(_components.p, null, "Es curioso de donde surge el término hogar, este corresponde con el lugar donde hace la lumbre dentro de una casa. Situémonos; estamos a principios del siglo XX,  pensemos en una antigua casa en el campo, con su familia patriarcal ", React.createElement(_components.a, {
    href: "http://bitacora.lasindias.com/el-modelo-familiar-del-futuro/"
  }, "tal y como explica David de Ugarte en las Indias"), " , la vida se sustenta en la agricultura o como mucho la ganadería, aún no ha llegado la industria. Una vez situados vamos a buscar el centro social de la familia, no es difícil encontrarlo, la familia llega del trabajo y se reúne junto al fuego, para calentarse, para cocinar ¿lógico no? al calor de este fuego la familia construye su propia comunidad, el hogar pasa de esta forma de nombrar la llama a representar a la comunidad."), "\n", React.createElement(_components.p, null, "Llegamos a la era industrial y primero la radio sustituye al fuego como centro social, luego la tele. La familia deja de tener una agenda propia y pasa a seguir la agenda estatal. Todo sucede muy rápido, aparecen entonces las familias-franquicias, la familia como comunidad se descompone, se convierte en una red."), "\n", React.createElement(_components.p, null, "De esta forma el hogar ha pasado de nombrar a una comunidad a tomar el significado de casa, solo un lugar donde comer y dormir, nada más, la familia como comunidad ha perdido el sentido y el hogar como zona de confort también."), "\n", React.createElement(_components.p, null, "Continuará…"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
